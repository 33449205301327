import _ from 'lodash';
import { NAMES } from '../actions/videos';
import replaceArrays from '../../utils/replace-arrays';

export default (state = {}, { payload, type }) => {
  if (type === NAMES.UPDATE_VIDEO_LIVE_STATUS) {
    return {
      ...state,
      [payload.id]: payload,
    };
  }

  const videos = _.get(payload, 'response.data.entities.videos');
  if (videos) {
    return _.mergeWith({}, state, videos, replaceArrays);
  }

  return state;
};
