import { combineReducers } from 'redux';

import plainStructure from 'connected-react-router/lib/structure/plain';
import createConnectRouter from 'connected-react-router/lib/reducer';

import slideMenuOpened from './reducers/slide-menu-opened';
import modules from './reducers/modules';

import videos from '../../redux/reducers/videos';
import channels from '../../redux/reducers/channels';
import channelVideos from '../../redux/reducers/channel-videos';

import configuration from '../../redux/reducers/configuration';
import appSettings from '../../redux/reducers/app-settings';
import currentChannelId from '../../redux/reducers/current-channel-id';
import search from '../../redux/reducers/search';
import channelInfo from '../../redux/reducers/channel-info';
import firstChannelVideoId from '../../redux/reducers/first-channel-video-id';
import shareOverlay from '../../redux/reducers/share-overlay';
import videoUrlsCache from '../../redux/reducers/video-urls-cache';
import selectedVideoId from '../../redux/reducers/selected-video-id';
import player from '../../redux/reducers/player';
import isVideosTouched from '../../redux/reducers/is-videos-touched';
import playback from '../../redux/reducers/playback';
import videoCards from '../../redux/reducers/video-cards/video-cards';
import payment from '../../redux/reducers/payment';
import lazyChannelVideos from '../../redux/lazy-channel-videos/reducer';
import windowSize from '../../redux/reducers/window-size';
import isLightbox from '../../redux/reducers/is-lightbox';
import fullScreenModal from '../../redux/reducers/full-screen-modal';
import comments from '../../redux/reducers/comments';
import { reducer as hydratedData } from '../../redux/hydrated-data/hydrated-data';
import controllerState from '../../redux/reducers/controller-state';

const createWidgetReducers = ({ history }) => ({
  router: createConnectRouter(plainStructure)(history),

  slideMenuOpened,
  modules,

  search,
  entities: combineReducers({
    videos,
    channels,
  }),
  channelVideos,
  currentChannelId,
  channelInfo,
  firstChannelVideoId,

  lazyChannelVideos,
  windowSize,
  isLightbox,

  videoUrlsCache,
  selectedVideoId,
  player,
  isVideosTouched,
  payment,

  configuration,
  appSettings,
  videoCards,
  shareOverlay,

  fullScreenModal,
  comments,
  hydratedData,
  playback,
  controllerState,
});

export default ({ history }) =>
  combineReducers(createWidgetReducers({ history }));
