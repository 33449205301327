import _ from 'lodash';
import { handleActions } from 'redux-actions';

import { NAMES as CHANNEL_GET } from '../../actions/channel/get-public';

const PATH_TO_CHANNELS = 'response.data.entities.channels';
const defaultState = {};

export default handleActions(
  {
    [CHANNEL_GET.SUCCESS]: (state, { payload }) => {
      const channels = _.get(payload, PATH_TO_CHANNELS);
      const channelData = _.values(channels)[0];

      return channelData || state;
    },
  },
  defaultState,
);
