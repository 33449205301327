/* eslint-disable camelcase */

import { toError } from '../../parsers/transport-error';
import {
  keysToCamelCase,
  keysToSnakeCase,
} from '@wix/wix-vod-shared/dist/src/common/utils/object';
import { CHANNEL_TYPES } from '@wix/wix-vod-constants/dist/common/channel-types';
import { parseChannel } from './parsers';
import { createCtx } from '@wix/wix-vod-shared/dist/src/common/utils/context';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { PurchasesService } from '@wix/wix-vod-gql-api/dist/src/public/purchases/purchases';
import { PaymentType } from '@wix/wix-vod-constants/dist/api-types/enums';
import { getPublicApi } from '../index';

export const ctx = createCtx();

export const getVideoPreviewUrls = (channelId, instance) => {
  // const service = ctx.getValue();
  const { oldPublicChannelService } = getPublicApi();
  return oldPublicChannelService
    .getVideoPreviewUrls(channelId, instance)
    .then((response) => response.data);
};

export const getMemberInfo = async (
  baseUrl,
  channelId,
  instance,
  { hydratedMemberInfo } = {},
) => {
  const { oldPublicChannelService } = getPublicApi();
  if (isGraphAPIEnabled()) {
    const purchasesService = new PurchasesService(instance, baseUrl);
    const purchases = await purchasesService.getPurchases();
    const items = purchases.map((purchase) => {
      const item = {
        dgsInfo: {
          [purchase.type]: purchase,
        },
        listId: channelId,
      };

      // in some cases we check if itemId is present - means that it is channel subscription (not video rent/sale) :)
      if (purchase.type !== PaymentType.SUBSCRIPTION) {
        item.itemId = purchase.id;
      }

      return item;
    });

    return {
      data: {
        items,
      },
    };
  }

  try {
    return keysToCamelCase(
      hydratedMemberInfo ||
        (await oldPublicChannelService.getMemberInfo(channelId, instance)),
    );
  } catch (error) {
    return toError(error.response);
  }
};

export const getPublic = async (
  channelId,
  { hydratedChannel, templateMetaSiteId } = {},
) => {
  // const service = ctx.getValue();
  const { oldPublicChannelService } = getPublicApi();

  const response =
    hydratedChannel ||
    (await oldPublicChannelService.getInfo(channelId, null, {
      params: { metaSiteId: templateMetaSiteId },
    }));

  try {
    const channel = keysToSnakeCase(response.data);
    channel.youtube_data = keysToCamelCase(channel.youtube_data);

    const { custom_cover_url, cover_url, featured_item, channel_type } =
      channel;

    if (
      !custom_cover_url &&
      !cover_url &&
      featured_item &&
      channel_type !== CHANNEL_TYPES.YOUTUBE_FEED
    ) {
      channel.cover_url = extractCoverFromVideoItem(channel.featured_item);
    }

    return { data: parseChannel(channel) };
  } catch (error) {
    return toError(error.response);
  }
};

const extractCoverFromVideoItem = (videoItem) =>
  videoItem.custom_cover_url || videoItem.cover_url;
