import _ from 'lodash';
import { ActionFunctionAny, createAction } from 'redux-actions';
import { Action } from 'redux';

type Actions = {
  START: ActionFunctionAny<Action<any>>;
  SUCCESS: ActionFunctionAny<Action<any>>;
  FAIL: ActionFunctionAny<Action<any>>;
  NAMES: {
    START: string;
    SUCCESS: string;
    FAIL: string;
  };
};

function toPayload(params = {}, response = null) {
  const payload: {
    params: any;
    response?: any;
  } = { params };

  if (response) {
    payload.response = response;
  }
  return payload;
}

function toMeta(params: any, response: any, meta: any = null) {
  if (meta && _.isArray(meta.analytics)) {
    return meta;
  }
  if (meta && meta.analytics && !meta.analytics.payload) {
    const { type, ...payload } = meta.analytics;
    meta.analytics = { type, payload };
  }
  return meta;
}

export default function (namespace: string): Actions {
  const START = `${namespace}.START`;
  const SUCCESS = `${namespace}.SUCCESS`;
  const FAIL = `${namespace}.FAIL`;

  return {
    NAMES: {
      START,
      SUCCESS,
      FAIL,
    },
    START: createAction(START, toPayload, toMeta),
    SUCCESS: createAction(SUCCESS, toPayload, toMeta),
    FAIL: createAction(FAIL, toPayload, toMeta),
  };
}
