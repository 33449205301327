import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { getAppLocale } from '../../services/locale/locale';

const asHttpResponse = (data) => (data ? { data } : null);

export const parseHydratedData = (source) => {
  const {
    currentPageId,
    siteOwnerId,
    fullSiteUrl,
    templateMetaSiteId,
    metaSiteId,
    viewMode,
  } = source;

  const translations = source.__TRANSLATIONS__;

  const currentSiteUser = source.__CURRENT_SITE_USER__;

  const locale =
    source.__LOCALE__ ||
    getAppLocale({
      appSettings: source.appSettings,
      siteLocale: source.siteLocale,
    });

  return {
    biToken: source.biToken,
    experiments: source.__EXPERIMENTS__,
    windowSize: source.windowSize,
    metaSiteId,
    locale,
    translations,
    siteUrl: source.__SITE_URL__,
    staticsVersion: source.staticsVersion,
    editorType: source.__EDITOR_TYPE__,
    instance: source.instance,
    instanceId: source.instanceId,
    viewMode,
    compId: source.compId,
    currentSiteUser: viewMode === VIEW_MODES.SITE ? currentSiteUser : null,
    deviceType: source.deviceType,
    templateMetaSiteId,
    currentPageId,
    siteOwnerId,
    fullSiteUrl,
    ...parseWidgetData(source.widgetData || {}),
  };
};

const parseWidgetData = (widgetData) => {
  if (widgetData.isV3Api) {
    return {
      channel: {
        data: {
          ...widgetData.__CHANNEL__,
          deal_info: widgetData.__CHANNEL__.dealInfo,
        },
      },
      channelVideos: asHttpResponse(widgetData.videos),
      singleVideo: asHttpResponse(widgetData.singleVideo),
      singleVideoId: widgetData.singleVideo?.id,
    };
  }
  return parseOldWidgetData(widgetData);
};

const parseOldWidgetData = (widgetData) => {
  const singleVideo = widgetData.__SINGLE_VIDEO__;
  const channelVideos = widgetData.__VIDEOS__;
  const memberInfo = widgetData.__MEMBER_INFO__;
  const channel = widgetData.__CHANNEL__;

  return {
    channel: asHttpResponse(channel),
    channelVideos: asHttpResponse(channelVideos),
    memberInfo: asHttpResponse(memberInfo),
    singleVideo: asHttpResponse(singleVideo),
    singleVideoId: singleVideo && singleVideo.item_id,
  };
};
