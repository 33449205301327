import {
  getNumberOfRows,
  getVideosInRowCount,
  isGridLayout,
  isStripLayout,
  isClassicLayout,
} from '../selectors/app-settings';

export const getVideosCount = ({ isMobile, styleParams }) => {
  if (isMobile) {
    // TODO: why are we returning undefined when it's isMobile???
    return;
  }

  const state = { appSettings: styleParams };

  if (isGridLayout(state) || isStripLayout(state) || isClassicLayout(state)) {
    return getNumberOfRows(state) * getVideosInRowCount(state);
  }
};
