import { handleActions } from 'redux-actions';

import { getResult } from '../../helpers/normalized';
import { clear } from '../../helpers/ids';

import { NAMES as SERVER_CHANNEL_VIDEOS_LIST } from '../../actions/channel/videos/list-public';

export default handleActions(
  {
    [SERVER_CHANNEL_VIDEOS_LIST.START]: clear,
    [SERVER_CHANNEL_VIDEOS_LIST.SUCCESS]: getResult,
  },
  [],
);
