import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { CHANNEL_PAYMENT_ACTIONS } from '../../actions/channel/payment.js';
import { UPDATE_CONTROLLER_STATE } from '../../actions/controller-state';

const PATH_TO_CHANNELS = 'response.data.entities.channels';
const defaultState = {};

export default handleActions(
  {
    [CHANNEL_PAYMENT_ACTIONS.NAMES.SUCCESS]: (state, { payload }) => {
      const channels = _.get(payload, PATH_TO_CHANNELS);
      const channelPaymentData = _.values(channels)[0];

      return {
        ...state,
        ...channelPaymentData,
      };
    },
    [UPDATE_CONTROLLER_STATE]: (state, { payload }) => {
      const { hasAccessToChannelByPricingPlan } = payload;
      return {
        ...state,
        hasAccessToChannelByPricingPlan,
      };
    },
  },
  defaultState,
);
