import { FedopsLoggerWrapper } from '@wix/wix-vod-shared/dist/src/fedops-logger/loggerWrapper';
import { InteractionsState } from '@wix/wix-vod-shared/dist/src/fedops-logger/interactions-state';
import {
  BASE_LOGGER,
  MOBILE_OVERLAY,
  MOBILE_WIDGET,
  DESKTOP_OVERLAY,
  DESKTOP_WIDGET,
  LOGGER_FNS,
  TABLET_WIDGET,
} from '../actions/fedopsLogger';

export function createFedopsHandler({
  loggerFactory,
  pubSub,
  compId,
  baseLoggerName,
  isSlave,
}) {
  const interactionsState = new InteractionsState({ compId, pubSub });

  const createLogger = (name) => {
    const logger = loggerFactory(name);
    return new FedopsLoggerWrapper({ logger, interactionsState });
  };

  const baseLogger = createLogger(baseLoggerName);
  const mobileOverlayLogger = createLogger('wix-vod-mobile-overlay');
  const mobileWidgetLogger = createLogger('wix-vod-mobile');
  const desktopOverlayLogger = createLogger('wix-vod-overlay');
  const desktopWidgetLogger = createLogger('wix-vod-widget');
  const tabletWidgetLogger = createLogger('wix-vod-tablet');

  isSlave
    ? interactionsState.requestInteractions()
    : interactionsState.subscribeOnInteractionsRequest();

  const loggersMapping = {
    [BASE_LOGGER]: baseLogger,
    [MOBILE_OVERLAY]: mobileOverlayLogger,
    [MOBILE_WIDGET]: mobileWidgetLogger,
    [DESKTOP_OVERLAY]: desktopOverlayLogger,
    [DESKTOP_WIDGET]: desktopWidgetLogger,
    [TABLET_WIDGET]: tabletWidgetLogger,
  };

  return ({ loggerName, logFn, interactionName }) => {
    const logger = loggersMapping[loggerName];
    switch (logFn) {
      case LOGGER_FNS.APP_LOADED:
        logger.appLoaded();
        break;
      case LOGGER_FNS.INTERACTION_START:
        logger.interactionStarted(interactionName);
        break;
      case LOGGER_FNS.INTERACTION_END:
        logger.interactionEnded(interactionName);
        break;
      default:
        console.warn(`unknown logFn: ${logFn}`);
    }
  };
}
