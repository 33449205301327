import { logBi } from './actions/bi';
import { pubSubPublish } from './actions/pub-sub/publish';
import { createLogBiHandler } from './handlers/bi';
import { createPubSubPublishHandler } from './handlers/pub-sub/publish';
import { createHandler } from './lib';
import { logFedopsAction } from './actions/fedopsLogger';
import {
  openFullScreenOverlay,
  openMobileOverlay,
} from './actions/openOverlay';
import { createVodBiLogger } from '../bi/create-bi';
import { setWidgetHeight } from './actions/resize/set-widget-height';
import { createSetWidgetHeightHandler } from './handlers/resize/set-widget-height';
import { resizeWindow } from './actions/resize/resize-window';
import { createResizeWindowHandler } from './handlers/resize/resize-window';
import { resizeComponent } from './actions/resize/resize-component';
import { createResizeComponentHandler } from './handlers/resize/resize-component';
import { createOpenOverlayHandlers } from './handlers/openOverlay';
import { performanceApplicationLoaded } from './actions/performanceLogger';
import { fitIntoView } from './actions/fit-into-view';
import { createFitIntoViewHandler } from './handlers/fit-into-view';
import { requestLogin } from './actions/user/request-login';
import { createRequestLoginHandler } from './handlers/user/login';
import { logout } from './actions/user/logout';
import { createLogoutHandler } from './handlers/user/logout';
import { getBiToken } from './getBiToken';
import { goToPricingPlanPicker } from './actions/go-to-pricing-plan-picker';
import { createGoToPricingPlanPickerHandler } from './handlers/go-to-pricing-plan-picker';

function createBi({ platformAPIs, appParams, compId }, debug) {
  const { biToken } = platformAPIs.bi;
  const { instanceId, instance } = appParams;

  // https://github.com/wix-private/native-components-infra/issues/131
  const factory =
    platformAPIs.biLoggerFactory().factory || platformAPIs.biLoggerFactory;

  return createVodBiLogger(
    factory,
    {
      biToken: biToken || getBiToken(instance, platformAPIs),
      videoInstanceID: instanceId,
      compId,
    },
    debug,
  );
}

export function createWorkerHandler(
  controllerConfig,
  {
    fedopsHandler,
    handleSetWidgetHeight,
    handleResizeWindow,
    handleResizeComponent,
    performanceApplicationLoadedHandler,
    handleFitIntoView,
    handleLogin,
    handleLogout,
    handleGoToPricingPlanPicker,
  },
  debug,
  flowAPI,
) {
  const { platformAPIs } = controllerConfig;
  const { httpClient } = flowAPI;
  const pubSub = platformAPIs.pubSub;
  const bi = createBi(controllerConfig, debug);

  const overlayHandlers = createOpenOverlayHandlers(controllerConfig);
  return createHandler([
    [logBi, createLogBiHandler(bi)],
    [pubSubPublish, createPubSubPublishHandler(pubSub)],
    [logFedopsAction, fedopsHandler],
    [setWidgetHeight, createSetWidgetHeightHandler(handleSetWidgetHeight)],
    [resizeWindow, createResizeWindowHandler(handleResizeWindow)],
    [resizeComponent, createResizeComponentHandler(handleResizeComponent)],
    [openFullScreenOverlay, overlayHandlers.openFullScreenOverlay],
    [openMobileOverlay, overlayHandlers.openMobileOverlay],
    [performanceApplicationLoaded, performanceApplicationLoadedHandler],
    [fitIntoView, createFitIntoViewHandler(handleFitIntoView)],
    [requestLogin, createRequestLoginHandler(handleLogin)],
    [logout, createLogoutHandler(handleLogout)],
    [
      goToPricingPlanPicker,
      createGoToPricingPlanPickerHandler(
        handleGoToPricingPlanPicker,
        httpClient,
      ),
    ],
  ]);
}
