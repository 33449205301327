import { IWixWindow } from '@wix/yoshi-flow-editor';
import { PublicChannelItem, PublicVideoItem } from '@wix/wix-vod-api/public';
import type { IHttpClient } from '@wix/http-client';
import { dashify } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';
import { PublicChannelService } from '@wix/wix-vod-gql-api/public';

type ViewMode = IWixWindow['viewMode']; // can not import the proper type;

export interface WidgetData {
  isV3Api: boolean;
  __TRANSLATIONS__: Record<string, string>;
  __VIDEOS__: { items: PublicVideoItem[] };
  __CHANNEL__: PublicChannelItem;
  __SINGLE_VIDEO__?: PublicVideoItem;
}

interface GetWidgetDataQueryParams {
  channelId?: string;
  videoId?: string;
  instance: string;
  locale?: string;
  videosCount?: number;
  isV3Api: boolean;
  metaSiteId?: string;
}

interface GetWidgetDataOptions {
  baseUrl: string;
  viewMode: ViewMode;
  httpClient: IHttpClient;
  translations: Record<string, string>;
  queryParams: GetWidgetDataQueryParams;
}

const getWidgetDataV2 = async ({
  baseUrl,
  viewMode,
  httpClient,
  queryParams,
}: GetWidgetDataOptions): Promise<WidgetData> => {
  const { protocol } = new URL(baseUrl);
  const domainWithProtocol =
    viewMode === 'Site' ? `${protocol}//${baseUrl.split('/')[2]}` : '';
  const paramsEntries = Object.entries(queryParams).filter(
    ([, value]) => value !== undefined,
  );
  const query = paramsEntries
    .reduce(
      (acc, [key, val]) => `${acc}${key}=${encodeURIComponent(String(val))}&`,
      '?',
    )
    .slice(0, -1);
  const url = `${domainWithProtocol}/wix-vod-server/widget-data${query}`;
  const response = await httpClient.get<WidgetData>(url);
  return response.data;
};

const getWidgetDataV3 = async ({
  queryParams,
  baseUrl,
  viewMode,
  translations: __TRANSLATIONS__,
}: GetWidgetDataOptions): Promise<WidgetData> => {
  const { videosCount, instance } = queryParams;
  const videoId = dashify(queryParams.videoId);
  const channelId = dashify(queryParams.channelId);
  if (!channelId) {
    throw Error('can not get widget data, no channel id');
  }
  const channelService = new PublicChannelService(
    instance,
    viewMode === 'Site' ? baseUrl : PRODUCTION_HOSTS.manage,
  );
  const widgetData = await channelService.getWidgetData({
    channelId,
    videosCount: Number(videosCount),
    singleVideoId: dashify(videoId) || null,
  });
  const { videos, channel, singleVideo } = widgetData;
  return {
    __TRANSLATIONS__,
    __VIDEOS__: videos,
    __SINGLE_VIDEO__: singleVideo,
    __CHANNEL__: channel,
    isV3Api: true,
  };
};

export const getWidgetData = async (
  options: GetWidgetDataOptions,
): Promise<WidgetData> => {
  const { isV3Api } = options.queryParams;
  return isV3Api ? getWidgetDataV3(options) : getWidgetDataV2(options);
};
