import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { restoreAction, createEventPublisher } from './Widget/worker/lib';
import {
  getApiBaseUrl,
  getExperimentsFromPetri,
  getMeshOverrides,
  patchControllerConfig,
} from './Widget/worker/helpers';
import { getHydratedSource } from './Widget/worker/helpers/get-hydrated-source';
import { create } from '@wix/fedops-logger';
import { VIDEO_APP_DEF_ID } from '@wix/wix-vod-constants/dist/common/app-def-id';
import { WIDGET_ID } from '@wix/wix-vod-constants/dist/common/widget-id';
import { getCurrentSiteUser } from './Widget/worker/helpers/current-site-user';
import EVENTS from './Widget/constants/events';
import { initPublicServices } from './Widget/api/public';
import { initPublicServicesV3 } from './Widget/api/v3/public';
import { createWorkerHandlerForOOI } from './Widget/worker/createHandlerForOOI';
import { createStore } from './Widget/worker/helpers/create-store';
import { subscribeToEvents } from './Widget/worker/helpers/subscribe-to-events';
import { getStoreBuilderForOOI } from './Widget/worker/helpers/get-store-builder-for-ooi';
import { getSeoData } from './Widget/worker/helpers/getSeoData';
import { isWixCodeSEOEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { createHandlers } from './Widget/worker/controller-handlers';
import { initPrivateServices } from './Widget/api/private';

export const createController =
  (
    createWorkerHandler: Function,
    getStoreBuilder: Function,
    isModal: boolean,
    fedOpsAppName: string,
  ): CreateControllerFn =>
  async (context: ControllerParams) => {
    const {
      controllerConfig: originalControllerConfig,
      flowAPI: {
        environment: { isSSR, isMobile, isViewer },
        translations,
        reportError,
        httpClient,
      },
      flowAPI,
    } = context;
    const {
      wixCodeApi,
      platformAPIs,
      setProps,
      appParams: { instance },
    } = originalControllerConfig;
    const baseUrl = getApiBaseUrl(wixCodeApi);
    const { publishEvent, getEvents, consumeEvents } = createEventPublisher({
      setProps: originalControllerConfig.setProps,
    });
    const controllerConfig = patchControllerConfig(originalControllerConfig, {
      getEvents,
    });
    const experiments = await getExperimentsFromPetri(
      controllerConfig,
      isViewer,
    );
    const fedOpsLogger = create<string>('wix-vod-widget');
    const performanceLogger =
      platformAPIs.fedOpsLoggerFactory?.getLoggerForWidget({
        appId: VIDEO_APP_DEF_ID,
        widgetId: WIDGET_ID,
      });
    const debug = process.env.NODE_ENV === 'development';

    let pendingCurrentSiteUser: any;

    const handleLogin = async (event: any) => {
      const currentSiteUser = await getCurrentSiteUser(event);

      platformAPIs.pubSub.publish(
        EVENTS.SESSION.LOGIN_USER,
        currentSiteUser,
        false,
      );

      pendingCurrentSiteUser = currentSiteUser;
    };

    wixCodeApi.user.onLogin(handleLogin);
    const hydratedSource = await getHydratedSource(controllerConfig, flowAPI, {
      experiments,
      fedOpsLogger,
      isModal,

      // a ? sign is for modal, in modal it's undefined. Not sure why, but since it's not used for modal anyway,
      // I'll leave it like this for now
      translations: translations?.all,
    });

    initPrivateServices(httpClient);
    initPublicServices(httpClient, instance, baseUrl);
    initPublicServicesV3(httpClient, instance, baseUrl);

    if (!isModal) {
      performanceLogger?.appLoadStarted();
    }

    return {
      async pageReady() {
        const currentPageId = wixCodeApi.site.currentPage?.id;

        const pageReadyData = {
          currentPageId,
        };

        const initialData = {
          ...hydratedSource,
          ...pageReadyData,
        };
        const { widgetData } = hydratedSource;

        if (isWixCodeSEOEnabled()) {
          const seoData = getSeoData({
            channel: widgetData.__CHANNEL__,
            videos: widgetData.__SINGLE_VIDEO__
              ? [widgetData.__SINGLE_VIDEO__]
              : widgetData.__VIDEOS__.items,
          });
          wixCodeApi.seo.renderSEOTags({
            itemType: 'VIDEO',
            itemData: seoData,
          });
        }

        const workerHandler = createWorkerHandler(
          controllerConfig,
          {
            performanceLogger,
            debug,
            fedOpsAppName,
          },
          flowAPI,
        );

        const dispatchEv = (action: any) =>
          workerHandler(restoreAction({ publishEvent, action }));

        if (pendingCurrentSiteUser) {
          initialData.__CURRENT_SITE_USER__ = pendingCurrentSiteUser;
        }

        const { handlers, getControllerState } = createHandlers(context);
        const storeProps = await createStore(
          getStoreBuilder,
          controllerConfig,
          initialData,
          setProps,
          debug,
          isSSR,
          isMobile,
          handlers,
          getControllerState,
        );

        const componentProps = {
          ...storeProps,
          dispatchEv,
          consumeEvents,
          captureException: reportError,
          renderingEnv: wixCodeApi.window.rendering.env,
          experiments,
          debug,
          isSSR,
          instance,
          handlers,
          ...getMeshOverrides(),
        };

        setProps(componentProps);

        // we cant publish events before initial setProps (check on invalid video id from url)
        subscribeToEvents(setProps, platformAPIs.pubSub);

        if (isSSR) {
          performanceLogger?.appLoaded();
        }

        return componentProps.appState;
      },
    };
  };

export default createController(
  createWorkerHandlerForOOI,
  getStoreBuilderForOOI,
  false,
  'wix-vod-widget',
);
