import { handleActions } from 'redux-actions';

import { SELECTED_VIDEO_ACTIONS } from '../actions/select-video';
import { NAMES as VIDEO_PLAYBACK_ACTIONS } from '../actions/player/change-playback-status';

function resetSelectedVideoID() {
  return null;
}

function setSelectedVideoID(state, { payload }) {
  return payload || null;
}

function setVideoIdFromPlayAction(state, { payload }) {
  return payload || state;
}

export default handleActions(
  {
    [SELECTED_VIDEO_ACTIONS.SET]: setSelectedVideoID,
    [SELECTED_VIDEO_ACTIONS.RESET]: resetSelectedVideoID,
    [VIDEO_PLAYBACK_ACTIONS.REQUEST_PLAY]: setVideoIdFromPlayAction,
  },
  null,
);
