import _ from 'lodash';
import { normalize } from 'normalizr';
import {
  channel,
  paymentInfoChannel,
  video,
  arrayOfVideos,
  arrayOfPaymentInfoVideos,
} from '../../schemas';

export function getResult(state, { payload }) {
  return _.get(payload, 'response.data.result');
}

export function normalizeVideo(data) {
  return normalize(data, video);
}

export function normalizeArrayOfVideos(data) {
  return normalize(data, arrayOfVideos);
}

export function normalizeArrayOfPaymentInfoVideos(data) {
  return normalize(data, arrayOfPaymentInfoVideos);
}

export function normalizeChannel(data) {
  return normalize(data, channel);
}

export function normalizePaymentInfoChannel(data) {
  return normalize(data, paymentInfoChannel);
}
