import _ from 'lodash';
import createAsyncActions from '../../helpers/create-async-actions';
import { getVideoCards } from '../../../api/public/video-cards/video-cards';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/dist/video-cards/types';
import { getFullSiteUrl, getInstance } from '../../hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../worker/lib';
import { getPublicApi } from '../../../api/public';

const ACTIONS = createAsyncActions('SERVER.VIDEO-CARDS.GET');
const { START, SUCCESS, FAIL } = ACTIONS;

const deeplinkCardTypes = [
  VIDEO_CARD_TYPES.CHANNEL,
  VIDEO_CARD_TYPES.VIDEO,
  VIDEO_CARD_TYPES.RECENT_VIDEO,
];

const hasDeeplink = (card) => _.includes(deeplinkCardTypes, card.type);

const appendDeeplinks = ({ cardsData, fallbackUrl, instance }) => {
  const { publicWidgetsService } = getPublicApi();
  return _.map(cardsData, (card) =>
    hasDeeplink(card)
      ? {
          ...card,
          url: card.actionItemId
            ? publicWidgetsService.buildVideoCardsDeeplinkUrl({
                channelId: card.actionListId,
                videoId: card.actionItemId,
                instance,
                fallbackUrl,
              })
            : publicWidgetsService.buildChannelCardsDeeplinkUrl({
                channelId: card.actionListId,
                instance,
                fallbackUrl,
              }),
        }
      : card,
  );
};

export const NAMES = ACTIONS.NAMES;

export const fetchVideoCards = createAsyncProxy(
  'fetchVideoCards',
  function (listId, itemId) {
    return function (dispatch, getState) {
      const params = { id: itemId };
      const state = getState();
      const instance = getInstance(state);
      const fullSiteUrl = getFullSiteUrl(state);

      dispatch(START(params));

      return dispatch(getVideoCards(listId, itemId))
        .then((response) =>
          appendDeeplinks({
            cardsData: response.data,
            fallbackUrl: fullSiteUrl,
            instance,
          }),
        )
        .then((data) => {
          dispatch(SUCCESS(params, data));
          return data;
        })
        .catch((errorResponse) => {
          dispatch(
            FAIL(params, errorResponse, {
              analytics: {
                type: 'error',
                name: 'shared.video-cards.get',
              },
            }),
          );
        });
    };
  },
);
