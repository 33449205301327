import { getSiteLocale, getViewMode } from './index';
import { ExperimentsBag } from '@wix/wix-experiments';
import { getCurrentSiteUser } from './current-site-user';
import { getBiToken } from '../getBiToken';
import { getVideosCount } from '../getVideosCount';

import DEVICE_TYPES from '../../constants/device-types';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { BaseLogger } from '@wix/fedops-logger';
import { getWidgetData, WidgetData } from './getWidgetData';
import { ControllerParams } from '@wix/yoshi-flow-editor';

const GET_WIDGET_DATA_INTERACTION = 'GET_WIDGET_DATA';

interface GetHydratedSourceOptions {
  experiments: ExperimentsBag;
  fedOpsLogger: BaseLogger<string>;
  isModal: boolean;
  translations: Record<string, string>;
}

export async function getHydratedSource(
  {
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    config,
    essentials: { httpClient },
  }: ControllerParams['controllerConfig'],
  flowAPI: ControllerParams['flowAPI'],
  {
    experiments,
    fedOpsLogger,
    isModal,
    translations,
  }: GetHydratedSourceOptions,
) {
  const viewMode = getViewMode(wixCodeApi);
  const siteOwnerId = platformAPIs.bi?.ownerId;
  const { instanceId, instance } = appParams;
  const __SITE_URL__ = wixCodeApi.location.baseUrl;
  const __CURRENT_SITE_USER__ = await getCurrentSiteUser(
    wixCodeApi.user.currentUser,
  );
  const styleParams = config.style.styleParams;
  const siteLocale = getSiteLocale(wixCodeApi);
  const fullSiteUrl = wixCodeApi.location.url; // full url
  const deviceType = wixCodeApi.window.formFactor.toLowerCase();
  const biToken = getBiToken(instance, platformAPIs);
  const metaSiteId = platformAPIs.bi?.metaSiteId;
  const templateMetaSiteId = (platformAPIs.bi as any)?.templateMetaSiteId;

  let widgetData: WidgetData = {} as WidgetData; // we need to understand what to do when we couldn't fetch it, why we return an empty object

  if (!isModal) {
    fedOpsLogger.interactionStarted(GET_WIDGET_DATA_INTERACTION);
    const { baseUrl } = wixCodeApi.location;

    const videosCount = getVideosCount({
      isMobile: deviceType === DEVICE_TYPES.MOBILE,
      styleParams,
    });

    try {
      // TODO: styleParams.fonts typings are wrong, the channelId and videoId are coming as string values
      const fonts = (styleParams.fonts || {}) as unknown as Record<
        string,
        string
      >;
      const { channelId, videoId } = fonts;
      widgetData = await getWidgetData({
        queryParams: {
          channelId,
          videoId,
          instance,
          videosCount,
          metaSiteId,
          locale: siteLocale,
          isV3Api: isGraphAPIEnabled(),
        },
        translations,
        httpClient,
        baseUrl,
        viewMode: wixCodeApi.window.viewMode,
      });
      fedOpsLogger.interactionEnded(GET_WIDGET_DATA_INTERACTION);
    } catch (e) {
      flowAPI.reportError(e as Error);
    }
  }

  return {
    siteOwnerId,
    instanceId,
    instance,
    viewMode,
    appSettings: styleParams,
    compId,
    metaSiteId,
    templateMetaSiteId,
    widgetData,
    __SITE_URL__,
    __CURRENT_SITE_USER__,
    __EXPERIMENTS__: experiments,
    siteLocale,
    fullSiteUrl,
    deviceType,
    biToken,
  };
}
