import { schema } from 'normalizr';

const channel = new schema.Entity('channels');
const paymentInfoChannel = new schema.Entity(
  'channels',
  {},
  {
    idAttribute: 'listId',
  },
);

const arrayOfChannels = [channel];

const video = new schema.Entity('videos');
const paymentInfoVideo = new schema.Entity(
  'videos',
  {},
  { idAttribute: 'itemId' },
);
const arrayOfVideos = [video];
const arrayOfPaymentInfoVideos = [paymentInfoVideo];

channel.define({
  featuredItem: video,
});

video.define({
  usedInLists: arrayOfChannels,
});

export {
  channel,
  paymentInfoChannel,
  arrayOfChannels,
  video,
  arrayOfVideos,
  arrayOfPaymentInfoVideos,
};
