import { parseCards } from '@wix/wix-vod-shared/dist/src/common/parsers/cards/cards';
import { toError } from '../../parsers/transport-error';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { publicApiV3 } from '../../v3/public';
import { getPublicApi } from '../index';

export const getVideoCards = (channelId, videoId) => () => {
  const { oldPublicVideoCardsService } = getPublicApi();
  const promise = isGraphAPIEnabled()
    ? publicApiV3.cards.getList(videoId)
    : oldPublicVideoCardsService.getList(channelId, videoId);

  return promise
    .then((response) => ({
      ...response,
      data: parseCards(response.data),
    }))
    .catch(toError);
};
