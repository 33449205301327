import { createAction } from 'redux-actions';
import { setMuted } from './sound';
import { setAutoPlayed } from './set-autoplayed';
import { isVideoPlayingOptimistic } from '../../../selectors/video-playback-status';
import { createProxy } from '../../../worker/lib';

export const NAMES = {
  PLAY: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET_PLAY',
  REQUEST_PLAY: 'CLIENT.VIDEO.PLAYBACK_STATUS.REQUEST_PLAY',
  ABORT_PLAY: 'CLIENT.VIDEO.PLAYBACK_STATUS.ABORT_PLAY',
  PAUSE: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET_PAUSE',
  REQUEST_PAUSE: 'CLIENT.VIDEO.PLAYBACK_STATUS.REQUEST_PAUSE',
  RESET: 'CLIENT.VIDEO.PLAYBACK_STATUS.RESET',
  END: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET_END',
  SET: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET',
};

const playVideoAction = createAction(NAMES.REQUEST_PLAY);
const pauseVideoAction = createAction(NAMES.REQUEST_PAUSE);

export const abortPlayVideo = createAction(NAMES.ABORT_PLAY);
export const resetPlaybackStatus = createAction(NAMES.RESET);
// Oh boy, don't even try to use it anywhere.
export const setPlaybackStatus = createAction(NAMES.SET);

export const playVideo = createProxy(
  'playVideo',
  (id, muted = false, autoPlayed = false) =>
    (dispatch) => {
      dispatch(setMuted(muted));
      dispatch(playVideoAction(id));
      dispatch(setAutoPlayed(autoPlayed));
    },
);

export const pauseVideo = createProxy(
  'pauseVideo',
  () => (dispatch, getState) => {
    const state = getState();

    // When user clicked on 'show action cards' we dispatch pause action which change playback status to 'PAUSED'.
    // But if video already ended and playback status is 'ENDED' we don't need to change it to 'PAUSED', because
    // it breaks UI
    if (isVideoPlayingOptimistic(state)) {
      dispatch(pauseVideoAction());
    }
  },
);
